import { ref, type Ref } from "vue";
import { authenticationService, meetingService } from "@/core/services";
import {
  IntranetIdEnum,
  type NotNestedMeetingRole,
  type OwnMeetingParticipant,
  type NotNestedDiploma,
} from "@sgdf/client";
import { useQuery } from "@tanstack/vue-query";

export const useMe = () => {
  const id = ref<string | null>(null);

  authenticationService.getUser().then((user) => {
    if (user?.pk) {
      id.value = user.pk;
    }
  });
  return id;
};

export const useMeDetails = () => {
  const id = useMe();
  return useQuery({
    queryKey: ["me", id.value],
    queryFn: () =>
      meetingService
        .getInstance()
        .getUsersApi()
        .usersMeRetrieve({
          uuid: id.value ?? "",
        }),
  });
};

export interface UserRole {
  isDirector: boolean;
  isFormer: boolean;
  isIntern: boolean;
  isSuperuser: boolean;
  detail: NotNestedMeetingRole | null;
}

export const useRole = () => {
  const meetingId = useMeetingId();
  const { data } = useMeDetails();

  const store = useUserStore();

  const { setRole } = store;

  watch(data, () => {
    if (meetingId && data.value && data.value.participations) {
      const currentParticipation = data.value.participations.find(
        (p) => p.meeting === meetingId
      );
      if (currentParticipation) {
        setRole(
          {
            isDirector:
              currentParticipation.role.intranet_id === IntranetIdEnum.NUMBER_2,
            isIntern:
              currentParticipation.role.intranet_id === IntranetIdEnum.NUMBER_0,
            isFormer: (
              [
                IntranetIdEnum.NUMBER_1,
                IntranetIdEnum.NUMBER_2,
              ] as unknown as number[]
            ).includes(currentParticipation.role.intranet_id),
            isSuperuser: data.value.is_superuser,
            detail: currentParticipation.role,
          },
          meetingId
        );
      } else if (data.value.is_superuser) {
        setRole(
          {
            isDirector: false,
            isIntern: false,
            isFormer: true,
            isSuperuser: true,
            detail: null,
          },
          meetingId
        );
      }
    }
  });

  return computed(() => store.getRoleByMeeting(meetingId));
};

export const useMyParticipation = () => {
  let participation = ref<OwnMeetingParticipant | null>(null);
  const diplomas = ref<NotNestedDiploma[] | null>(null);
  const meetingId = useMeetingId();
  const { data, refetch: internalRefetch, ...rest } = useMeDetails();
  const isLoading = ref<boolean>(rest.isLoading.value);

  watch(data, () => {
    if (meetingId && data.value && data.value.participations) {
      const currentParticipation = data.value.participations.find(
        (p) => p.meeting === meetingId
      );
      if (currentParticipation) {
        participation.value = currentParticipation;
      }
    }
    if (data.value?.diplomas) {
      diplomas.value = data.value.diplomas;
    }

    isLoading.value = rest.isLoading.value;
  });

  const refetch = async () => {
    const { data: internalData, ...refectRest } = await internalRefetch();

    if (meetingId && internalData && internalData.participations) {
      const currentParticipation = internalData.participations.find(
        (p) => p.meeting === meetingId
      );
      return {
        participation: currentParticipation,
        diplomas: internalData.diplomas,
        ...refectRest,
      };
    }
  };

  return {
    participation,
    refetch,
    diplomas,
    ...rest,
    isLoading,
  };
};
